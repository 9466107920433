
.download_text{
    margin: 5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.imgs{
    max-inline-size: -webkit-fill-available;
}

.download_icons_a{
    display: flex;
    justify-content: center;
}

.description_text{
    text-align: justify;
}
.outliers{
    display:flex;
    gap:8px;
}

.download_div{
    text-align: center;
}

.final_div{
    margin-bottom: 4rem;
}


@media (max-width: 800px) {
    
    .download_icons {
        margin:5rem;
        display: block;
    }
    .navigation_link {
        display: none;
     }
     .download_text {
        margin: 2rem;
     }
    .sidebar{
        width: 6rem;
    }

    .home_div{
        display: grid;
        gap:2rem;
        margin-top: 2rem;
    }
    .home_icon{
        display: none;
    }
    .home_text{
        font-size:3rem;
    }
    .text_mobile{
        font-size: medium;
    }
    .grid_mobile{
        display: block;
    }
    .color_outliers{
        display:none;
    }
    .download_div{
        text-align: center;
    }
    .icons-container{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
}

@media (min-width:800px) {
    .home_div{
        grid-template-rows: repeat(2, minmax(0, 1fr));
    }
    .download_icons{
        margin:5rem;
    }    
}

.powered-by-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    gap:0.5rem;
    margin: 2rem;
}
.icon{
    align-content: center;
}
.icons-container{
    display: flex;
    gap: 1.5rem;
}
